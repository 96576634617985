const QS: any[] = [
    {
        name: 'QS-2024-10-2-A',
        rate: 2.5,
        term: 2,
    },
    {
        name: 'QS-2024-10-2-B',
        rate: 2.7,
        term: 3,
    },
    {
        name: 'QS-2024-10-2-C',
        rate: 2.9,
        term: 6,
    },
];

export default QS;
