import React from 'react';
import styled from 'styled-components';
import QS from '../data/qs';

const Container = styled.div`
    position: fixed;
    background-color: #fff;
    width: 250px;
    height: 400px;
    border-radius: 8px;
    padding: 20px 30px;
`;

const CloseButton = styled.button`
    border: none;
    background-color: transparent;
    font-size: 20px;
    height: 30px;
    width: 30px;
    cursor: pointer;
`;

const Close = styled.img`
    width: 20px;
`;

const List = styled.li`
    list-style-type: none;
    > ul > button {
        background-color: transparent;
        border: none;
        color: #00a2ff;
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const Modal = ({
    onClick,
    close,
}: {
    onClick: ({ rate, term }: { rate: number; term: number }) => void;
    close: () => void;
}) => {
    return (
        <Container>
            <CloseButton onClick={close}>
                <Close src='https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-close-512.png' />
            </CloseButton>
            <List>
                {QS.map(({ name, rate, term }) => (
                    <ul key={name}>
                        <button onClick={() => onClick({ rate, term })}>
                            {name}
                        </button>
                    </ul>
                ))}
            </List>
        </Container>
    );
};
