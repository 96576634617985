import { head, last, sum } from '@fxts/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Footer } from '../components/Footer';
import { Modal } from '../components/Modal';
import { Button } from '../components/shared/Button';
import { Input } from '../components/shared/Input';
import { MEMBER } from '../data/member';
import { PATHS } from '../paths';
export interface DataType {
    amount: string;
    rate: string;
    term: string;
}
interface qsObject {
    rate: number;
    term: number;
    amount: number;
    resultInterest: number;
    resultAmount: number;
}

export interface useFormType {
    rate: number;
    priorRate: number;
    amount: number;
    term: number;

    totalRate: number;
    totalAmount: number;
    resultInterest: number;
    resultAmount: number;
}

const Header = styled.header`
    display: flex;
    justify-content: space-between;
`;

const Logo = styled.img`
    max-width: 182px;
    margin-bottom: 10px;
`;

const Container = styled.div`
    padding: 0;
    max-width: 400px;
`;
const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    width: 100%;
    margin: auto;
    width: 100%;
`;

const Name = styled.p``;

const LogoutButton = styled.button`
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #fff;
    text-decoration: underline;
`;

const InfoContainer = styled.div`
    color: #fff;
    display: flex;
`;

const Title = styled.h2`
    color: #fff;
    font-size: 18px;
    margin-bottom: 20px;
`;

const Table = styled.table`
    > tr {
        width: 100%;
        overflow: auto;
    }
    width: 100%;
    overflow: auto;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 182px;
    margin-bottom: 20px;
`;

const ResultContainer = styled.div`
    max-width: 182px;
    text-align: start;
    color: #fff;
    margin-top: 10px;
    > div {
        margin: 5px 0;
        font-size: 14px;
        font-weight: bold;
    }
`;

const ResultTableContainer = styled.div`
    color: #fff;
    table,
    th,
    td {
        border: 1px solid #fff;
        border-collapse: collapse;
    }
    > table {
        width: 100%;
        > thead {
            font-size: 11px;
            > tr > th {
                text-align: start;
            }
        }
        > tbody {
            font-size: 10px;
            text-align: start;
        }
        > td {
        }
    }
`;

const InLine = styled.div`
    display: flex;
    > p {
        width: 20%;
        margin: 2.5px 10px;
        font-size: 10px;
    }
`;

export const Main = () => {
    const [data, setData] = useState<qsObject[]>();
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen((prev) => !prev);
    };

    const {
        register,
        setValue,
        watch,
        formState: { errors },
    } = useForm<useFormType>({
        defaultValues: {
            amount: 0,
            resultAmount: 0,
            priorRate: 0,
            rate: 0,
            resultInterest: 0,
            term: 0,
            totalAmount: 0,
            totalRate: 0,
        },
    });

    const reset = () => {
        setValue('amount', 0);
        setValue('resultAmount', 0);
        setValue('priorRate', 0);
        setValue('rate', 0);
        setValue('resultInterest', 0);
        setValue('term', 0);
        setValue('totalAmount', 0);
        setValue('totalRate', 0);
        setData([]);
    };

    const [member, setMember] = useState<{
        name: string;
        id: string;
        pwd: string;
        level: string;
    }>();

    const { id } = useParams();
    const navigate = useNavigate();

    // useEffect(() => {
    //     const loggedInMember = MEMBER.find((member) => member.id === id);
    //     if (!loggedInMember) {
    //         onLogout();
    //     }
    //     setMember(loggedInMember);
    // }, []);

    useEffect(() => {
        if (!data) {
            return;
        }
        const lastTotalAmount = Number(last(data)?.resultAmount);

        if (!lastTotalAmount) {
            return;
        }

        setValue('amount', Math.floor(lastTotalAmount));
    }, [data]);

    const addQs = () => {
        if (!watch('amount') || !watch('rate') || !watch('term')) {
            return alert('모든 항목을 입력해주세요!');
        }

        const resultInterest =
            watch('amount') * watch('term') * watch('rate') * 0.01;

        setData((prev) => {
            if (!prev) {
                return [
                    {
                        amount: Number(watch('amount')),
                        term: Number(watch('term')),
                        rate: Number(watch('rate')),
                        resultInterest: Number(resultInterest),
                        resultAmount:
                            Number(resultInterest) + Number(watch('amount')),
                    },
                ];
            }

            return [
                ...prev,
                {
                    amount: Number(watch('amount')),
                    term: Number(watch('term')),
                    rate: Number(watch('rate')),
                    resultInterest: Number(resultInterest),
                    resultAmount:
                        Number(resultInterest) + Number(watch('amount')),
                },
            ];
        });
    };

    const totalTerm = (): { year: number; month: number } | undefined => {
        if (!data) {
            return;
        }

        const term = sum(data.map((a) => a.term));
        const year = Math.floor(term / 12);
        const month = term % 12;

        return { year, month };
    };

    const onClickQs = ({ rate, term }: { rate: number; term: number }) => {
        setIsOpen(false);

        setValue('term', Number(term));
        setValue('rate', Number(rate));

        return;
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const onClickPrice = (e: number) => {
        setValue('amount', Number(watch('amount')) + e);
    };
    const onClickRate = (e: number) => {
        const value = (e + watch('rate')).toFixed(1);
        setValue('rate', Number(value));
    };
    const onClickTerm = (e: number) => {
        setValue('term', Number(watch('term')) + e);
    };

    // const onLogout = () => {
    //     setMember({ name: '', id: '', pwd: '', level: '' });
    //     navigate(PATHS.LOGIN);

    //     return alert('로그아웃 되었습니다.');
    // };

    return (
        <>
            {isOpen && <Modal onClick={onClickQs} close={closeModal} />}
            <Container>
                <Header>
                    <Logo src='https://static.wixstatic.com/media/214089_3b4f049564fc450f83462cdd38cef518~mv2.png/v1/fill/w_257,h_40,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/logo%20(3).png' />
                    <InfoContainer>
                        {/* <Name>
                            {member && `${member.name} ${member.level}`}{' '}
                        </Name>
                        <LogoutButton onClick={onLogout}>로그아웃</LogoutButton> */}
                    </InfoContainer>
                </Header>
                <Title>QS 계산기</Title>
                {/* <p
                    style={{
                        marginBottom: '20px',
                        fontSize: '12px',
                        color: '#fff',
                    }}
                >
                    한국은행 기준 금리:
                    {!!api ? (
                        `${api?.KeyStatisticList?.row[0].DATA_VALUE} ${api?.KeyStatisticList?.row[0].UNIT_NAME}`
                    ) : (
                        <ClipLoader color='#fff' size={10} />
                    )}
                </p> */}
                <Button
                    name='상품 리스트'
                    onClick={toggleModal}
                    style={{ marginBottom: '20px' }}
                ></Button>
                <InnerContainer>
                    <div>
                        <Input
                            label='투자금'
                            register={register('amount', {
                                valueAsNumber: true,
                            })}
                            onClick={onClickPrice}
                            errors={errors}
                            inputType='amount'
                            setValue={setValue}
                        />
                        <Input
                            label='이율(월)'
                            register={register('rate', {
                                valueAsNumber: true,
                            })}
                            onClick={onClickRate}
                            errors={errors}
                            inputType='rate'
                            setValue={setValue}
                        />
                        {/* <Input
        label="우대 이율"
        onChange={priorInterestOnChangeEvent}
        value={priorInterest}
      /> */}
                        <Input
                            label='투자 기간(개월)'
                            register={register('term')}
                            onClick={onClickTerm}
                            errors={errors}
                            inputType='term'
                            setValue={setValue}
                        />
                        <ButtonContainer>
                            <Button name='차수 추가' onClick={addQs}></Button>
                            <Button name='초기화' onClick={reset}></Button>
                        </ButtonContainer>
                    </div>
                    {/* 원금, 이자, 만기 상환금 */}
                    <ResultContainer>
                        <div>
                            원금:{' '}
                            {data &&
                                head(data) &&
                                `₩${Math.floor(
                                    Number(head(data)?.amount),
                                ).toLocaleString('ko-kr')}`}
                        </div>
                        <div>
                            원금대비 이자 비율:{' '}
                            {data &&
                                Number(last(data)?.rate) > 0 &&
                                `${(
                                    ((Number(last(data)?.resultAmount) -
                                        Number(head(data)?.amount)) /
                                        Number(head(data)?.amount)) *
                                    100
                                ).toFixed(2)}%`}
                        </div>
                        <div>
                            이자:{' '}
                            {data &&
                                head(data) &&
                                `₩${Math.floor(
                                    Number(last(data)?.resultAmount) -
                                        Number(head(data)?.amount),
                                ).toLocaleString('ko-KR')}`}
                        </div>
                        <div>
                            투자기간:{' '}
                            {data &&
                                `${
                                    !!totalTerm()?.year
                                        ? `${totalTerm()?.year}년 `
                                        : ''
                                }${totalTerm()?.month}개월`}
                        </div>
                        <div style={{ fontSize: '18px', marginTop: '15px' }}>
                            총 만기 상환금:{' '}
                            {data &&
                                head(data) &&
                                `₩${Math.floor(
                                    Number(last(data)?.resultAmount),
                                ).toLocaleString('ko-KR')}`}
                            {/* {total &&
                            `₩${Math.floor(
                                total?.totalResultAmount,
                            ).toLocaleString('ko-KR')}`} */}
                        </div>
                    </ResultContainer>
                </InnerContainer>
                <ResultTableContainer>
                    {data && data.length > 0 && (
                        <Table>
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        차수
                                    </th>
                                    <th
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        원금
                                    </th>
                                    <th
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        이율(월)
                                    </th>
                                    <th
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        투자기간
                                    </th>
                                    <th
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        총 이율
                                    </th>
                                    <th
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        이자
                                    </th>
                                    <th
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        만기상환금
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(({ amount, rate, term }, index) => {
                                    const resultInterest =
                                        amount * term * rate * 0.01;
                                    return (
                                        <tr key={uuidv4()}>
                                            <td
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {index + 1}회
                                            </td>
                                            <td
                                                style={{
                                                    textAlign: 'end',
                                                }}
                                            >{`₩${Number(
                                                Math.floor(amount),
                                            ).toLocaleString('ko-KR')}`}</td>
                                            <td
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >{`${rate}%`}</td>
                                            <td
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >{`${term}개월`}</td>
                                            <td
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >{`${(rate * term).toFixed(
                                                2,
                                            )}%`}</td>
                                            <td
                                                style={{ textAlign: 'end' }}
                                            >{`₩${Number(
                                                Math.floor(resultInterest),
                                            ).toLocaleString('ko-KR')}`}</td>
                                            <td
                                                style={{
                                                    textAlign: 'end',
                                                }}
                                            >{`₩${Math.floor(
                                                Number(resultInterest) +
                                                    Number(amount),
                                            ).toLocaleString('ko-KR')}`}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </ResultTableContainer>
            </Container>
            <Footer />
        </>
    );
};

// TODO 정기 예금 수익률(비교)
// 투자금, 1년 이율, 납입개월 (1,2년), 세금, 비교했을떄 차액
//
