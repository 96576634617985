import React from 'react';
import styled from 'styled-components';

const PriceContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

const Button = styled.button`
    margin: 0 2px;
    border: none;
    background-color: #fff;
    border-radius: 8px;
    width: 39px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
`;

const ButtonTypes = {
    amount: [
        {
            value: 100000000,
            label: '억',
        },
        {
            value: 10000000,
            label: '천만',
        },
        {
            value: 1000000,
            label: '백만',
        },
        {
            value: 100000,
            label: '십만',
        },
    ],
    term: [
        {
            value: 12,
            label: '1yr',
        },
        {
            value: 6,
            label: '6mo',
        },
        {
            value: 3,
            label: '3mo',
        },
        {
            value: 1,
            label: '1mo',
        },
    ],
    rate: [
        {
            value: 1,
            label: '1%',
        },
        {
            value: 0.5,
            label: '0.5%',
        },
        {
            value: 0.2,
            label: '0.2%',
        },
        {
            value: 0.1,
            label: '0.1%',
        },
    ],
};

export const PriceButton = ({
    onClick,
    type,
}: {
    onClick: (e: number) => void;
    type: 'amount' | 'rate' | 'term';
}) => {
    return (
        <div>
            <PriceContainer>
                {ButtonTypes[type].map((a) => (
                    <Button key={a.value} onClick={() => onClick(a.value)}>
                        +{a.label}
                    </Button>
                ))}
            </PriceContainer>
        </div>
    );
};
