import styled from 'styled-components';

const Foot = styled.footer`
    margin-top: 50px;
    padding: 0;
    width: 100%;
    text-align: start;
    color: #fff;
    > span {
        font-size: 12px;
    }
`;

const Title = styled.p`
    margin-bottom: 5px;
`;
export const Footer = () => {
    return (
        <Foot>
            <Title>PS FINE SERVICE</Title>
            <span>QS CALCULATOR</span>
            <br />
            <span>Dev. by Junmook Choi</span>
            <br />
            <br />
            <span>서울특별시 강남구 테헤란로 411 4층</span>
            <br />
            <span>Teheran-ro 441, Gangnam-gu, Seoul, Republic of Korea</span>
        </Foot>
    );
};
