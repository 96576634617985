import { Dispatch, SetStateAction, useEffect } from 'react';
import {
    FieldError,
    FieldErrors,
    FieldValue,
    FieldValues,
    UseFormRegister,
    UseFormReturn,
    UseFormSetValue,
} from 'react-hook-form';
import styled from 'styled-components';
import { DataType, useFormType } from '../../pages/Main';
import { PriceButton } from './PriceButton';

const Container = styled.div`
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
`;

const InputContainer = styled.div`
    max-width: 182px;
`;

const Label = styled.div`
    margin-right: 10px;
    color: #fff;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: start;
`;

const InputForm = styled.input`
    border-radius: 10px;
    border: none;
    height: 28px;
    padding: 0 10px;
`;

export const Input = ({
    label,
    register,
    inputType,
    onClick,
}: {
    label: string;
    register: any;
    errors: FieldErrors;
    setValue: UseFormSetValue<useFormType>;
    inputType: 'amount' | 'rate' | 'term';
    onClick: (e: number) => void;
}) => {
    return (
        <Container>
            <InputContainer>
                <Label>{label}</Label>
                <PriceButton onClick={onClick} type={inputType} />
                <InputForm {...register} type='number' />
            </InputContainer>
        </Container>
    );
};
