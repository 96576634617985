import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Main } from './pages/Main';
import styled from 'styled-components';
import { Link, Route, Routes } from 'react-router-dom';
import { PATHS } from './paths';
import { Login } from './pages/Login';
import { Helmet } from 'react-helmet';

const Container = styled.div`
    width: 100%;
    margin: auto;
    padding: 20px;
    height: 100%;
`;

function App() {
    return (
        <Container>
            <Helmet>
                <title>QS 계산기</title>
                <meta name='description' content='피에스파인서비스 QS 계산기' />
                <link
                    rel='icon'
                    type='image/png'
                    href='%PUBLIC_URL%/favicon.png'
                />

                <meta name='og:image' content='%PUBLIC_URL%/metaImg.png' />
            </Helmet>
            {/* <Routes>
                <Route path={PATHS.LOGIN} element={<Login />} />
            </Routes> */}
            <Routes>
                <Route path={PATHS.MAIN} element={<Main />} />
            </Routes>
        </Container>
    );
}

export default App;
