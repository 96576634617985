import { CSSProperties } from 'react';
import styled from 'styled-components';

const ButtonElement = styled.button`
    background-color: #fff;
    border: none;
    width: 80px;
    height: 30px;
    border-radius: 14px;
    color: #1c6366;
`;
export const Button = ({
    name,
    onClick,
    style,
}: {
    name: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    style?: CSSProperties;
}) => {
    return (
        <div>
            <ButtonElement onClick={onClick} style={style}>
                {name}
            </ButtonElement>
        </div>
    );
};
